import { Skeleton, Text, VStack } from "@chakra-ui/react";
import React from "react";

interface IShowValueSkeleton {
  width?: string;
}

const ShowValueSkeleton = ({ width = "50%" }: IShowValueSkeleton) => {
  return (
    <VStack
      display="flex"
      alignItems="flex-start"
      justifyContent="flex-start"
      textAlign="left"
      w={width}
      spacing={1}
    >
      <Skeleton>
        <Text fontWeight={600} color="brown.200" fontSize="0.937rem">
          value...
        </Text>
      </Skeleton>
      <Skeleton>
        <Text fontWeight={400} color="grey.400" fontSize="0.75rem">
          description...
        </Text>
      </Skeleton>
    </VStack>
  );
};

export default ShowValueSkeleton;
