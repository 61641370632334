import { Center, Flex, Img, Link, Menu, MenuButton } from "@chakra-ui/react";
import {
  NavLink as LinkReact,
  useMatch,
  useResolvedPath,
} from "react-router-dom";

export default function NavItem({ image, route }: any) {
  let resolved = useResolvedPath(route);
  let match = useMatch({ path: resolved.pathname, end: true });
  return (
    <Flex flexDir="column" w="100%" h="4.375rem" textAlign="center">
      <Menu>
        <Link
          _disabled={{}}
          as={LinkReact}
          to={route}
          borderRadius={0}
          _hover={{ color: "#D91F05" }}
          w={"100%"}
          h="80%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          style={{ opacity: match ? "1" : "0.3" }}
        >
          <MenuButton
            w="full"
            p={1}
            borderRadius="0.188rem"
            textAlign="center"
            style={{
              borderRight: match
                ? "0.25rem solid #D91F05"
                : "0.25rem solid white",
            }}
          >
            <Center>
              <Img src={image} width="1.125rem" />
            </Center>
          </MenuButton>
        </Link>
      </Menu>
    </Flex>
  );
}
