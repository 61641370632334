import { Progress, Text } from "@chakra-ui/react";
import React from "react";

interface IProgressBar {
  value?: number;
  color?: string;
}

const ProgressBarWithPercent = ({ value, color = "red" }: IProgressBar) => {
  return (
    <>
      <Progress
        colorScheme="red"
        size="sm"
        w="7.5rem"
        value={value}
        borderRadius="5.625rem"
      />
      <Text color={color} fontSize="1rem" fontWeight={700}>
        {value}%
      </Text>
    </>
  );
};

export default ProgressBarWithPercent;
