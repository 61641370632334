import React from "react";
import { List, ListItem, VStack, Text } from "@chakra-ui/react";
import { Checkbox } from "@chakra-ui/react";

interface CheckItem {
  label?: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  checked?: any;
  isIndeterminate?: boolean;
}

export default function ModalCheckboxItem({
  label,
  value,
  onChange,
  name,
  checked,
  isIndeterminate,
}: CheckItem) {
  return (
    <List p={1}>
      <ListItem>
        <VStack
          display="flex"
          alignItems="left"
          justifyContent="left"
          textAlign="left"
        >
          <Checkbox
            isIndeterminate={isIndeterminate}
            name={name}
            size="md"
            colorScheme="red"
            css={`
              > span:first-of-type {
                box-shadow: unset;
              }
            `}
            value={value}
            onChange={onChange}
            isInvalid
            isChecked={checked}
          >
            <VStack
              pl={2}
              display="flex"
              alignItems="left"
              justifyContent="left"
              textAlign="left"
              spacing={0}
            >
              <Text color="#626166" fontSize="1rem">
                {label}
              </Text>
              <Text color="#B0C1E1" fontSize="1rem">
                {value}
              </Text>
            </VStack>
          </Checkbox>
        </VStack>
      </ListItem>
    </List>
  );
}
