import { useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { ITankService } from "../api";
import { api } from "../services/api";

export interface CentersDTO {
  label?: string;
  value?: string;
}

export interface IStockService {
  materialID?: string;
  materialDesc?: string;
  centerID?: string;
  centerDesc?: string;
  storageLocationID?: string;
  storageLocationDesc?: string;
  quantity?: number;
  tank?: ITankService;
  tankUsage?: number;
}

interface IStockPerm {
  storageLocationDesc?: string;
  tank?: ITank;
  tankUsage?: number;
}

interface ITank {
  capacity: number;
}

export interface IProductGroup {
  productGroup?: string;
  centerID?: string;
  totalVolumeAvailable?: number;
  totalVolumeAvailableMixed?: number;
  totalCapacity?: number;
  totalCapacityUsage?: number;
  totalQuote?: number;
  totalSales?: number;
  totalVolumeTempAvailable?: number;
  totalQuoteUsage?: number;
  daysOfStoch?: number;
  totalVolumeTempAvailableNVTB?: number;
  totalVolumeTempAvailableVTB?: number;
  alert: string;
  stochPerm?: IStockPerm[];
}

export interface DashboardContextProps {
  stockData: IProductGroup[];
  getStock: () => void;
  getStockById: (i: string) => {};
  centers: CentersDTO[];
  isLoading: boolean;
}

export const DashboardContext = React.createContext(
  {} as DashboardContextProps
);

export const DashboardContextProvider: React.FC = ({ children }) => {
  const [stock, setStock] = useState<IStockService[]>([]);
  const [stockData, setStockData] = useState<IProductGroup[]>([]);
  const [centers, setCenters] = useState<CentersDTO[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useToast();

  async function getStock(): Promise<any> {
    try {
      const response = await api.get<IStockService[]>("rest/center");
      setStock(response.data);
    } catch (err) {
      console.log(err);
      toast({
        title: "Erro ao carregar os centros!",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  }

  async function getStockById(id: string): Promise<any> {
    try {
      setIsLoading(true);
      const response = await api.get<IProductGroup[]>(
        `rest/dashboard?action=product_group&centerID=${id}`
      );
      setStockData(response.data);
    } catch (err) {
      console.log(err);
      toast({
        title: "Ocorreu um erro ao carregar os dados!",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    const setListStock = new Set();
    const filteredCenter = stock.filter((value) => {
      const duplication = setListStock.has(value.centerID);
      setListStock.add(value.centerID);
      return !duplication;
    });

    setCenters(
      filteredCenter.map((itemstock) => ({
        label: itemstock.centerDesc,
        value: itemstock.centerID,
      }))
    );
  }, [stock]);

  return (
    <DashboardContext.Provider
      value={{
        isLoading,
        getStock,
        getStockById,
        stockData,
        centers,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboard = () => {
  const context = React.useContext(DashboardContext);
  if (Object.values(context).length === 0) {
    throw new Error(
      "useDashboard deve ser utilizado dentro de um DashboardContextProvider"
    );
  }
  return context;
};
