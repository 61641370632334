import axios, { AxiosRequestConfig } from "axios";

export const api = axios.create({
  baseURL: "https://supplymiddleware.grupoatem.com.br",
})

const validateToken = (token: string | null, config: AxiosRequestConfig) => {
  if (token) {
    config.headers = {
      Authorization: `Bearer ${token}`
    }
  }
  return config;
};

const tokenInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const token = localStorage.getItem('token');
  return validateToken(token, config);
}

const tokenError = (error: any) => {
  return Promise.reject(error);
}

api.interceptors.request.use(tokenInterceptor, tokenError);
api.interceptors.response.use(function (response){
  return response;
},
function (error){
  if(error.response.status ==401){
    return error.response;
  }else{
    return Promise.reject(error);
  }
}

);

