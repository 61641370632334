import { createContext, useCallback, useEffect, useState } from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import { api } from "../services/api";
import Cookies from "universal-cookie";
import appConfig from "../config/appConfig";
import LoadingSpinner from "../components/LoadingSpinner";

interface formLogin {
  email: string;
  password: string;
}

interface IAuthContext {
  isAuthenticated: boolean;
  login: (data: formLogin) => Promise<String>;
  logout: Function;
  user?: String;
}

export const AuthContext = createContext({} as IAuthContext);

const AuthProvider: React.FC<{}> = ({ children }) => {
  const [user, setUser] = useLocalStorage<any>("userInfo", undefined);
  let isAuthenticated = !!user;

  const [refreshToken, setRefreshToken] = useState<String>();
  const [dateExpires, setDateExpires] = useState<number>(0);
  
  const cookies = new Cookies();
  const localCookies = {
    ...cookies.get("othersProperties"),
    AccessToken: cookies.get("accessToken"),
    RefreshToken: cookies.get("refreshToken"),
  };

  useEffect(() => {
    if (localCookies.AccessToken) {
      const accessToken = localCookies.AccessToken;
      localStorage.setItem("token", accessToken);
      setUser(localCookies.meta);
      window.location.href = `${appConfig.supplyDomain}`;
    } else {
      window.location.href = `${appConfig.loginDomain}?url=${appConfig.supplyDomain}&rules=supply-chain`;
    }
  }, [localCookies.AccessToken, localCookies.meta, setUser]);

  async function login(data: formLogin): Promise<String> {
    const response = await api.post("/auth/login", data);
    const expireInThirtyMinutes = 600000;
    setDateExpires(expireInThirtyMinutes);
    if (response.data.AccessToken) {
      const accessToken = response.data.AccessToken;
      localStorage.setItem("token", accessToken);
    }
    if (response.data.RefreshToken) {
      const currentRefreshToken = response.data.RefreshToken;
      setRefreshToken(currentRefreshToken);
    }

    setUser(response.data.meta);
    if (response.status == 401) {
      return Promise.reject(response);
    }
    const group = response.data.meta.groups;
    let result = group.filter((item: string) => item === "supply-chain");
    if (result.length === 0) {
      return Promise.reject(response);
    }

    return Promise.resolve("/dashboard");
  }

  const [isLoading, setIsLoading] = useState(false);
  async function logout()  {
    return new Promise<void>( async (resolve,reject) => {
      setIsLoading(true);
      try {
        console.log('Logout...');
        localStorage.clear();
        isAuthenticated = false;
    
        cookies.set('accessToken',      '' , { path: '/' , domain: appConfig.subdomain   });
        cookies.set('refreshToken',     '' , { path: '/' , domain: appConfig.subdomain   });
        cookies.set('othersProperties', '' , { path: '/' , domain: appConfig.subdomain   });
        
        setTimeout( async () => {
          window.location.reload();
          window.location.replace(`${appConfig.loginDomain}?url=${appConfig.supplyDomain}&rules=supply-chain`)
          console.log('Logout Finished');
        }, 2000);

        setIsLoading(false);
        
        resolve();
      } catch (error) {
        console.log("Failed -> ",error);
        setIsLoading(false);
        reject();
      }
    })

  }

  const getRefreshToken = useCallback(async () => {
    if (refreshToken) {
      const token = { token: refreshToken };
      try {
        const response = await api.post("/auth/refresh", token);
        if (response.data.AccessToken) {
          const accessToken = response.data.AccessToken;
          localStorage.setItem("token", accessToken);
        }
      } catch (err) {
        logout();
      }
    }
  }, [refreshToken]);

  useEffect(() => {
    if (dateExpires) {
      setInterval(async () => {
        await getRefreshToken();
      }, dateExpires);
    }
  }, [dateExpires, getRefreshToken]);

  return (
    <AuthContext.Provider value={{ login, isAuthenticated, user, logout }}>
      {isLoading ? <LoadingSpinner /> : children}
      {/* {children} */}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
