import { Flex, VStack } from "@chakra-ui/react";
import * as React from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";

const Layout: React.FC = ({ children }) => {
  return (
    <Flex color="#F4F7F9" w="full" overflowX="hidden">
      <Sidebar />
      <VStack bg="#F4F7F9" w="full">
        <Header />
        {children}
      </VStack>
    </Flex>
  );
};

export default Layout;
