import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  HStack,
  Img,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import imgBell from "../../assets/images/icons/sidebar/notification_icon.svg";
import imgLogout from "../../assets/images/icons/header/icon_logout.svg";
import {
  DashboardContext,
  useDashboard,
  CentersDTO,
} from "../../hooks/DashboardContext";
import { AuthContext } from "../../contexts/AuthContext";

export default function Header() {
  const dashboard = useDashboard();
  const [currentItemMenu, setCurrentItemMenu] = useState("");
  const { getStockById } = useContext(DashboardContext);
  const [codeStock, setCodeStock] = useState(dashboard.centers[0]?.value);
  const { logout } = useContext(AuthContext);

  const marginLeftDefault = "3.125rem";
  const marginRightDefault = "8rem";

  useEffect(() => {
    if (codeStock) {
      getStockById(codeStock);
    }
  }, [codeStock]);

  useEffect(() => {
    setCurrentItemMenu(
      dashboard.centers[0]?.label
        ? dashboard.centers[0]?.value + " - " + dashboard.centers[0]?.label
        : "Carregando..."
    );
    setCodeStock(dashboard.centers[0]?.value);
  }, [dashboard.centers]);

  let itemsMenu = dashboard.centers;

  function handleClick(event: any) {
    setCodeStock(event.target.value);
    let itemDropdownToShow = `${
      event.target.value
    } - ${event.target.getAttribute("label-selected")}`;
    setCurrentItemMenu(itemDropdownToShow);
  }

  return (
    <>
      <Flex w="full">
        <HStack w="100%" ml={marginLeftDefault} mt="1.8rem">
          <Box flex={2}></Box>
          <Spacer />
          <Box flex={2} textAlign="right" pr={marginRightDefault}>
            <Menu>
              <MenuButton
                py={2}
                transition="all 0.3s"
                _focus={{ boxShadow: "none" }}
              >
                <HStack>
                  <VStack
                    display={{ base: "none", md: "flex" }}
                    alignItems="flex-start"
                    spacing="0.063rem"
                    ml="0.125rem"
                    color="#D91F05"
                    fontWeight="bold"
                  >
                    <Text fontSize="sm">{currentItemMenu}</Text>
                  </VStack>
                  <Box display={{ base: "none", md: "flex" }}>
                    <FiChevronDown color="#D91F05" />
                  </Box>
                </HStack>
              </MenuButton>

              <MenuList
                color={"#736161"}
                overflow={"auto"}
                h="17.625rem"
                overflowY="auto"
                css={{
                  "&::-webkit-scrollbar": {
                    width: "0.4rem",
                  },
                  "&::-webkit-scrollbar-track": {
                    width: "0.375rem",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#736161",
                    borderRadius: "0.5rem",
                  },
                }}
              >
                <MenuItem
                  fontWeight="bold"
                  color="#313B45"
                  _hover={{ hover: "none" }}
                >
                  Centros
                </MenuItem>
                <MenuDivider />

                {itemsMenu.map((child: CentersDTO) => (
                  <MenuItem
                    key={child.value}
                    label-selected={child.label}
                    {...child}
                    value={child.value}
                    onClick={handleClick}
                  >
                    {`${child.value} - ${child.label}`}
                  </MenuItem>
                ))}
              </MenuList>

              <Button
                variant="link"
                color="#D91F05"
                fontWeight="bold"
                fontSize="0.875rem"
                onClick={() => logout()}
                style={{
                  marginLeft: "2rem",
                  height: "1rem",
                  marginBottom: "0rem",
                  paddingTop: "0rem",
                }}
              >
                <Img src={imgLogout} width="1rem"></Img>
                <Text ml="0.2rem">SAIR</Text>
              </Button>
            </Menu>
          </Box>
        </HStack>
      </Flex>
    </>
  );
}
