
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  api: {
    url: "https://supplymiddleware.grupoatem.com.br/rest",
    refreshInterval: 5 * 60 * 1000, // cada 1 segundo tem 1000 milésimo de segundo - multilpicado por 60 você tem 1 minuto - multiplicado por 5 você tem - 5 minutos
  },
  keyStorage: {
    lastBasisSelected: "@centralDash-lastBasis",
    keyTokenRefresh: "@centralDash-token",
    savedFavorite: "@centralDash-savedFavorite",
  },
  loginDomain:"https://authcog.grupoatem.com.br/#/",
  supplyDomain:"https://devsuprimentos.grupoatem.com.br/#/dashboard",
  subdomain: ".grupoatem.com.br",
  // loginDomain:"http://localhost:3001/",
  // supplyDomain:"http://localhost:3000/#/dashboard",
  // subdomain: "localhost",
}
