import { Box, Button, useToast } from "@chakra-ui/react";
import React from "react";
import ModalCheckboxItem from "../ModalCheckboxItem";
import SimpleModal from "../SimpleModal";
import { useEffect, useState } from "react";
import { useDashboard } from "../../hooks/DashboardContext";
import { IProductGroup } from "../../hooks/DashboardContext";
import { api } from "../../services/api";

interface IChecklist {
  checked: boolean;
  label?: string;
  value?: string;
}

const ModalDownloadReports: React.FC = () => {
  const dashboard = useDashboard();
  const [checklist, setChecklist] = useState<IChecklist[]>([]);
  const [dataCards, setDataCards] = useState<IProductGroup[]>([]);
  const [itemsSelected, setItemsSelected] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoadingDownload, setIsLoadingDownload] = useState<boolean>(false);
  const toast = useToast();
  const allChecked = checklist.every((item) => item.checked);
  const isIndeterminate = checklist.some((item) => item.checked) && !allChecked;

  function dataAtualFormatada() {
    const dataAtual = new Date();
    const dia = dataAtual.getDate();
    const mes = dataAtual.getMonth() + 1;
    const ano = dataAtual.getFullYear();
    const horas = dataAtual.getHours();
    const minutos = dataAtual.getMinutes();
    return dia + "." + mes + "." + ano + "_as_" + horas + "h" + minutos + "min";
  }

  async function downloadReports(): Promise<any> {
    const getFile = async () => {
      return await api.get<any>(
        `${`/rest/reports/productGroup?centerID=${itemsSelected}`}`,
        {
          responseType: "arraybuffer",
          headers: { "Content-Type": "blob" },
        }
      );
    };
    try {
      setIsLoadingDownload(true);
      const resp = await getFile();
      const link = document.createElement("a");
      const date = dataAtualFormatada();
      const fileName = `Relatório_Suprimentos_${date}.xlsx`;
      link.setAttribute("download", fileName);
      link.href = URL.createObjectURL(new Blob([resp.data]));
      document.body.appendChild(link);
      link.click();
      link.remove();
      toast({
        title: "Download feito com sucesso!",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    } catch (err) {
      console.log(err);
      toast({
        title: "Erro ao fazer download!",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    } finally {
      setIsLoadingDownload(false);
    }
  }

  useEffect(() => {
    setDataCards(dashboard.stockData);
  }, [dashboard.stockData]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newChecklist = checklist.map((item) => {
      if (item.value === event.target.value) {
        return { ...item, checked: event.target.checked };
      }
      return item;
    });
    setChecklist(newChecklist);
    const codes = newChecklist.filter((item) => item.checked);
    //@ts-ignore
    setItemsSelected(codes.map((item) => item.value));
  };

  const handleChangeAllSelect = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newChecklist = checklist.map((item) => ({
      ...item,
      checked: event.target.checked,
    }));
    setChecklist(newChecklist);
    const codes = newChecklist.filter((item) => item.checked);
    //@ts-ignore
    setItemsSelected(codes.map((item) => item.value));
  };

  useEffect(() => {
    if (dashboard.centers) {
      setChecklist(
        dashboard.centers.map((item) => ({
          ...item,
          checked: false,
        }))
      );
    }
  }, [dashboard.centers]);

  return (
    <SimpleModal
      onOpenModelCustom={() => setIsOpen(true)}
      onCloseCustom={() => {
        setIsOpen(false);
      }}
      isOpenModal={isOpen}
      titleModal="Download de produtos por centro"
      titleButton="FAZER DOWNLOAD DE RELATÓRIO"
      buttonCloseEnable={false}
      customButton={
        <Button
          disabled={itemsSelected.length === 0}
          fontSize="1rem"
          fontWeight={400}
          borderRadius="0.25rem"
          bg="#D91F05"
          variant="solid"
          color="white"
          p="0.25rem"
          _focus={{ outline: "none" }}
          _hover={{ hover: "none" }}
          padding="0.75rem 2.5rem 0.75rem 2.5rem"
          _active={{ active: "none" }}
          //@ts-ignore
          onClick={downloadReports}
          isLoading={isLoadingDownload}
          loadingText="Baixando..."
        >
          BAIXAR
        </Button>
      }
    >
      <Box pb={1.5} fontWeight={600}>
        <ModalCheckboxItem
          name="allSelect"
          checked={allChecked}
          isIndeterminate={isIndeterminate}
          onChange={handleChangeAllSelect}
          label="Selecionar todos os centros"
        />
      </Box>
      {checklist.map((child: any) => (
        <ModalCheckboxItem
          checked={child.checked}
          key={child.value}
          {...child}
          onChange={handleChange}
        />
      ))}
    </SimpleModal>
  );
};

export default ModalDownloadReports;
