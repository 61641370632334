import React from "react";
import RoutesComponent from "./components/Routes";
import AuthProvider from "./contexts/AuthContext";
import { DashboardContextProvider } from "./hooks/DashboardContext";

export const App: React.FC = () => {
  return (
    <>
      <AuthProvider>
        <DashboardContextProvider>
          <RoutesComponent />
        </DashboardContextProvider>
      </AuthProvider>
    </>
  );
};

export default App;
