import React from "react";
import Dashboard from "../../pages/Dashboard";
import { HashRouter, Route, Routes } from "react-router-dom";
import RedirectToLogin from "../../pages/RedirectToLogin";
import { PrivateRoute } from "./PrivateRoute";
export const RoutesComponent: React.FC = () => {
  return (
    <>
      <HashRouter>
        <Routes>
          <Route element={<PrivateRoute />}>
            <Route path="/dashboard" element={<Dashboard />} />
          </Route>
          <Route path="/" element={<RedirectToLogin />}></Route>
        </Routes>
      </HashRouter>
    </>
  );
};

export default RoutesComponent;
