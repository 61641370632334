import { Text, VStack } from "@chakra-ui/react";
import React from "react";

interface IShowValue {
  value?: number;
  description?: string;
  width?: string;
}

const ShowValue = ({ value, description, width = "50%" }: IShowValue) => {
  return (
    <VStack
      display="flex"
      alignItems="flex-start"
      justifyContent="flex-start"
      textAlign="left"
      w={width}
      spacing={0}
    >
      <Text fontWeight={600} color="brown.200" fontSize="1rem">
        {value}
      </Text>
      <Text fontWeight={400} color="grey.400" fontSize="0.75rem">
        {description}
      </Text>
    </VStack>
  );
};

export default ShowValue;
