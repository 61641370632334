import React from "react";
import SimpleModal from "../SimpleModal";
import { Box, Flex, HStack, Spacer, Text, VStack } from "@chakra-ui/react";
import SimpleTag from "../SimpleTag";
import ShowValueInModal from "./ShowValueInModal";
import { IProductGroup } from "../../hooks/DashboardContext";

interface IModalCardProduct extends IProductGroup {
  onCloseCustom: () => void;
  isOpenModal: boolean;
  onOpenModelCustom: () => void;
}

const ModalCardProduct = ({ ...props }: IModalCardProduct) => {
  return (
    <SimpleModal
      isCentered={true}
      titleModalCustom={
        <>
          <HStack spacing={0}>
            <Text fontSize="1.5rem" color="black.400" fontWeight="bold">
              {props.productGroup}
            </Text>
            <Text
              fontSize="0.813rem"
              color="grey.400"
              display="grid"
              pt={3}
              pl={1}
            >
              em m
            </Text>
            <Text as="sub" fontSize="0.813rem" color="grey.400" display="grid">
              3
            </Text>
          </HStack>
        </>
      }
      buttonCloseEnable={false}
      onOpenModelCustom={props.onOpenModelCustom}
      onCloseCustom={props.onCloseCustom}
      isOpenModal={props.isOpenModal}
      size="4xl"
    >
      <Flex w="full" p="1.25rem 1.25rem 0.625rem 0.625rem">
        <Box w="100%" color="white">
          <SimpleTag color="red.100" bg="red.50" label=" Volume em uso" />

          <HStack mt="0.75rem">
            <ShowValueInModal
              value={props.totalVolumeAvailable}
              title="Puro"
              width="25%"
            />
            <ShowValueInModal
              value={props.totalVolumeAvailableMixed}
              title="Misturado"
              width="25%"
            />
          </HStack>
        </Box>
        <Spacer />
        <Box w="100%" color="white">
          <SimpleTag
            color="yellow.500"
            bg="yellow.400"
            label=" Volume de venda do dia"
          />
          <HStack mt="0.75rem">
            <ShowValueInModal
              value={props.totalSales}
              width="100%"
              description="Total de puro e misturado"
            />
          </HStack>
        </Box>
      </Flex>

      <Flex w="full" p="1.25rem 1.25rem 0.625rem 0.625rem">
        <VStack w="100%">
          <Box w="100%" color="white" h="60px">
            <SimpleTag
              color="green.500"
              bg="green.400"
              label="Capacidade total do tanque"
            />
            <HStack mt="0.75rem">
              <ShowValueInModal title="Tanque" width="30%" />
              <ShowValueInModal title="Capacidade" width="25%" />
              <ShowValueInModal title="Taxa de ocupação" width="40%" />
            </HStack>
          </Box>

          <Box
            w="100%"
            color="white"
            h="190px"
            overflowY="auto"
            css={{
              "&::-webkit-scrollbar": {
                width: "0.4rem",
              },
              "&::-webkit-scrollbar-track": {
                width: "0.375rem",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#736161",
                borderRadius: "0.5rem",
              },
            }}
          >
            {props.stochPerm &&
              props.stochPerm.map((item, index) => (
                <HStack mt="0" key={index}>
                  {item.storageLocationDesc && (
                    <ShowValueInModal
                      valueWithString={item.storageLocationDesc}
                      width="30%"
                    />
                  )}
                  {item.tank && (
                    <ShowValueInModal
                      valueWithString={Intl.NumberFormat("pt-BR").format(
                        item.tank.capacity
                      )}
                      width="25%"
                    />
                  )}
                  {item.tankUsage && (
                    <ShowValueInModal
                      valueWithString={`${item.tankUsage.toFixed(1)}%`}
                      width="40%"
                    />
                  )}
                </HStack>
              ))}
          </Box>
        </VStack>

        <Spacer />
        <Box w="100%" color="white">
          <VStack
            w="full"
            display="flex"
            alignItems="flex-start"
            justifyContent="flex-start"
            textAlign="left"
          >
            <SimpleTag
              color="blue.500"
              bg="blue.400"
              label="Volume cota do dia"
            />
            <HStack mt="0.75rem" w="full" pb="1.25rem">
              <ShowValueInModal value={props.totalQuote} width="50%" />
              <ShowValueInModal
                valueWithString={`${props.totalQuoteUsage}%`}
                title="Em uso"
                width="25%"
              />
            </HStack>

            <SimpleTag
              color="blue.500"
              bg="grey.100"
              label="Volume temporário na balsa"
            />
            <HStack mt="0.75rem" w="full" pb="1.25rem">
              <ShowValueInModal
                value={props.totalVolumeTempAvailableVTB}
                width="100%"
              />
            </HStack>

            <SimpleTag
              color="blue.500"
              bg="grey.100"
              label="Volume temporário fora da balsa"
            />
            <HStack mt="0.75rem" w="full">
              <ShowValueInModal
                value={props.totalVolumeTempAvailableNVTB}
                width="100%"
              />
            </HStack>
          </VStack>
        </Box>
      </Flex>
    </SimpleModal>
  );
};

export default ModalCardProduct;
