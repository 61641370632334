import { Box, Flex, IconButton, Img } from "@chakra-ui/react";
import { useState } from "react";
import imgHome from "../../assets/images/icons/sidebar/icon_home.svg";
import imgCheck from "../../assets/images/icons/sidebar/icon_checklist.svg";
import imgMenu from "../../assets/images/icons/sidebar/icon_menu.svg";
import NavItem from "./NavItem";

export default function Sidebar() {
  const [navSize, changeNavSize] = useState("large");
  return (
    <Flex
      pos="sticky"
      h="100vh"
      boxShadow="0 0.25rem 0.75rem 0 rgba(0,0,0,0.02)"
      w={navSize === "small" ? "4.688rem" : "5rem"}
      flexDir="column"
      bg="white"
    >
      <Flex flexDir="column" as="nav">
        {/* @ts-ignore */}
        <IconButton
          borderRadius={0}
          w="full"
          h="5rem"
          background="#D91F05"
          color="white"
          icon={<Img src={imgMenu} alt="menu" width="1.5rem" />}
          onClick={() => {
            if (navSize === "small") changeNavSize("large");
            else changeNavSize("small");
          }}
          _focus={{ outline: "none" }}
          _hover={{ hover: "none" }}
          _active={{ active: "none" }}
        />

        <Box mt="0.25rem">
          <NavItem navSize={navSize} image={imgHome} route="/dashboard" />
        </Box>
      </Flex>
    </Flex>
  );
}
