import {
  Box,
  Flex,
  HStack,
  IconButton,
  Img,
  Progress,
  Skeleton,
  Spacer,
} from "@chakra-ui/react";
import React from "react";
import SimpleCard from "../SimpleCard";
import { Text } from "@chakra-ui/react";
import SimpleTag from "../SimpleTag";
import ShowValueSkeleton from "./ShowValueSkeleton";
import iconButtonModal from "../../assets/images/icons/card/icon_information_details.svg";

const CardProductSkeleton = () => {
  return (
    <Box display="flex" pr="1.875rem" p="1rem 2rem 0.25rem 0.5rem">
      <SimpleCard>
        <Flex
          w="full"
          borderBottom="0.063rem solid rgba(176,193,225,0.3) "
          p="1.25rem 1.25rem 0.625rem 1.25rem"
          _hover={{
            cursor: "grab",
          }}
        >
          <Skeleton>
            <Text fontSize="1.5rem" color="black.400" fontWeight="bold">
              titleLoading...
            </Text>
          </Skeleton>
          <Text
            fontSize="0.813rem"
            color="grey.400"
            display="grid"
            pt={3}
            pl={1}
          >
            em m
          </Text>
          <Text
            as="sub"
            fontSize="0.813rem"
            color="grey.400"
            display="grid"
            pt={3}
          >
            3
          </Text>
          <Spacer />
          <Box>
            <IconButton
              variant="unstyled"
              aria-label="iconButtonModal"
              icon={<Img src={iconButtonModal} />}
              size="0.625rem"
              h="0.938rem"
              _focus={{ outline: "none" }}
              _active={{ active: "none" }}
              pt={2}
              pr={3}
            />
          </Box>
        </Flex>

        <Flex
          w="full"
          p="1.25rem 1.25rem 0.625rem 1.25rem"
          _hover={{
            cursor: "grab",
          }}
        >
          <Box w="100%" color="white">
            <SimpleTag color="red.100" bg="red.50" label=" Volume em uso" />

            <HStack mt="0.75rem">
              <ShowValueSkeleton />
              <ShowValueSkeleton />
            </HStack>
          </Box>
          <Spacer />
          <Box w="100%" color="white">
            <SimpleTag
              color="yellow.500"
              bg="yellow.400"
              label=" Volume de venda do dia"
            />
            <HStack mt="0.75rem">
              <ShowValueSkeleton />
            </HStack>
          </Box>
        </Flex>

        <Flex
          w="full"
          p="0.625rem 1.25rem 0.625rem 1.25rem"
          _hover={{
            cursor: "grab",
          }}
        >
          <Box w="100%" color="white">
            <SimpleTag
              color="green.500"
              bg="green.400"
              label="Capacidade total do tanque"
            />
            <HStack mt="0.75rem">
              <Skeleton>
                <Text>0.000.000</Text>
              </Skeleton>
            </HStack>
          </Box>
          <Spacer />
          <Box w="100%" color="white">
            <SimpleTag
              color="blue.500"
              bg="blue.400"
              label="Volume cota do dia"
            />
            <HStack mt="0.75rem">
              <ShowValueSkeleton />
            </HStack>
          </Box>
        </Flex>
        <Flex
          w="full"
          borderBottom="0.063rem solid rgba(176,193,225,0.3) "
          p="0.625rem 1.25rem 2.5rem 1.25rem"
          _hover={{
            cursor: "grab",
          }}
        >
          <Box w="100%" color="white">
            <SimpleTag color="red.100" bg="grey.100" label="Taxa de ocupação" />
            <HStack>
              <Progress
                colorScheme="red"
                size="sm"
                w="7.5rem"
                isIndeterminate
                borderRadius="5.625rem"
              />

              <Skeleton>
                <Text color="red" fontSize="1rem" fontWeight={700}>
                  0.0%
                </Text>
              </Skeleton>
            </HStack>
          </Box>

          <Spacer />
          <Box w="100%" color="white">
            <SimpleTag color="red.100" bg="grey.100" label="Cota dia em uso" />
            <HStack>
              <Progress
                colorScheme="red"
                size="sm"
                w="7.5rem"
                isIndeterminate
                borderRadius="5.625rem"
              />

              <Skeleton>
                <Text color="red" fontSize="1rem" fontWeight={700}>
                  0.0%
                </Text>
              </Skeleton>
            </HStack>
          </Box>
        </Flex>

        <Flex
          w="full"
          p="0.625rem 1.25rem 0.625rem 1.25rem"
          _hover={{
            cursor: "grab",
          }}
        >
          <Box w="100%" color="white">
            <SimpleTag
              color="blue.500"
              bg="grey.100"
              label="Volume temporário de balsa"
            />
            <HStack>
              <Skeleton>
                <Text>0.000.000</Text>
              </Skeleton>
            </HStack>
          </Box>
          <Spacer />
          <Box w="100%" color="white">
            <SimpleTag
              color="blue.500"
              bg="grey.100"
              label="Volume temporário fora da balsa"
            />
            <HStack>
              <Skeleton>
                <Text>0.000.000</Text>
              </Skeleton>
            </HStack>
          </Box>
        </Flex>
      </SimpleCard>
    </Box>
  );
};

export default CardProductSkeleton;
