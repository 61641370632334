import { Box, Button, Flex, Heading, HStack, Spacer } from "@chakra-ui/react";
import { useContext, useEffect, useRef, useState } from "react";
import CardProduct from "../../components/CardProduct";
import Layout from "../../components/Layout";
import { DashboardContext, useDashboard } from "../../hooks/DashboardContext";
import { IProductGroup } from "../../hooks/DashboardContext";
import CardProductSkeleton from "../../components/CardProductSkeleton";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import ModalDownloadReports from "../../components/ModalDownloadReports";

const marginLeftDefault = "3.125rem";
const marginRightDefault = "8rem";

const DashboardPage: React.FC = () => {
  const dashboard = useDashboard();
  const { getStock, isLoading } = useContext(DashboardContext);
  const [dataCards, setDataCards] = useState<IProductGroup[]>([]);
  const containerCardsRef = useRef<HTMLDivElement>();

  let scroll = 0;
  function moveToLeft() {
    scroll = scroll == 0 ? 0 : scroll - 300;
    containerCardsRef.current?.scrollTo({ behavior: "smooth", left: scroll });
  }
  useEffect(() => {
    scroll = 0;
  }, [dataCards, setDataCards]);

  function moveToRight() {
    let limit = containerCardsRef.current?.scrollWidth ?? 0;
    scroll =
      scroll + (containerCardsRef.current?.offsetWidth ?? 0) >= limit
        ? scroll
        : scroll + 300;
    containerCardsRef.current?.scrollTo({ behavior: "smooth", left: scroll });
  }

  useEffect(() => {
    setDataCards(dashboard.stockData);
  }, [dashboard.stockData]);

  useEffect(() => {
    getStock();
  }, []);

  function formatNumberToBr(value: number) {
    return value ? Intl.NumberFormat("pt-BR").format(value) : 0;
  }

  const buildDataToCard = (requestedData: IProductGroup[]) => {
    let data = requestedData.map((item: any) => {
      let newItem = { ...item };
      newItem = {
        ...item,
        totalVolumeAvailable: formatNumberToBr(item.totalVolumeAvailable),
        totalVolumeAvailableMixed: formatNumberToBr(
          item.totalVolumeAvailableMixed
        ),
        totalCapacity: formatNumberToBr(item.totalCapacity),
        totalCapacityUsage: item.totalCapacityUsage.toFixed(1),
        totalQuote: formatNumberToBr(item.totalQuote),
        totalSales: formatNumberToBr(item.totalSales),
        totalVolumeTempAvailable: formatNumberToBr(
          item.totalVolumeTempAvailable
        ),
        totalQuoteUsage: item.totalQuoteUsage.toFixed(1),
        daysOfStoch: formatNumberToBr(item.daysOfStoch),
        totalVolumeTempAvailableNVTB: formatNumberToBr(
          item.totalVolumeTempAvailableNVTB
        ),
        totalVolumeTempAvailableVTB: formatNumberToBr(
          item.totalVolumeTempAvailableVTB
        ),
      };
      return newItem;
    });
    return data;
  };

  return (
    <Layout>
      <Box w="full">
        <Heading
          fontSize="1.5rem"
          fontWeight="bold"
          color="black.400"
          ml={marginLeftDefault}
          pt="0.125rem"
          pb="0.125rem"
        >
          Dashboard Suprimentos
        </Heading>
      </Box>
      <Flex w="full">
        <HStack w="100%" ml={marginLeftDefault} mt="1.8rem">
          <Box flex={2}>
            <Heading
              fontSize="1rem"
              fontWeight="bold"
              color="black.400"
              pt="0.125rem"
              pb="0.125rem"
            >
              PRODUTOS
            </Heading>
          </Box>
          <Spacer />
          <Box flex={2} textAlign="right" pr={marginRightDefault}>
            <ModalDownloadReports />
          </Box>
        </HStack>
      </Flex>

      <Flex w="full">
        <HStack w="100%" ml={marginLeftDefault} mt="0.2rem">
          <Box flex={2}>
            <Button
              onClick={() => moveToLeft()}
              style={{
                border: "2rem 2rem 2rem 2rem solid rgba(176, 193, 225, 0.25)",
                borderRadius: 100,
                backgroundColor: "#e5effa",
                color: "#b6c1d7",
                width: 30,
                height: 30,
                minWidth: 30,
                alignSelf: "center",
              }}
            >
              <ChevronLeftIcon w={8} h={8} color="#626166" />
            </Button>
          </Box>
          <Spacer />
          <Box flex={2} textAlign="right" pr={marginRightDefault}>
            <Button
              onClick={() => moveToRight()}
              style={{
                border: "2rem 2rem 2rem 2rem solid rgba(176, 193, 225, 0.25)",
                borderRadius: 100,
                backgroundColor: "#e5effa",
                color: "#b6c1d7",
                width: 30,
                height: 30,
                minWidth: 30,
                alignSelf: "center",
              }}
            >
              <ChevronRightIcon w={8} h={8} color="#626166" />
            </Button>
          </Box>
        </HStack>
      </Flex>

      <Flex w="100%">
        <div
          //@ts-ignore
          ref={containerCardsRef}
          style={{ width: "100%", overflow: "hidden", maxWidth: "100%" }}
        >
          <HStack w="40rem" ml={marginLeftDefault} mt="0.3rem">
            {isLoading || dataCards.length === 0 ? (
              <>
                <CardProductSkeleton />
                <CardProductSkeleton />
                <CardProductSkeleton />
              </>
            ) : (
              buildDataToCard(dataCards) &&
              buildDataToCard(dataCards).map((child?: any, index?: number) => (
                <Box
                  display="flex"
                  pr="1.875rem"
                  p="0.5rem 0.4rem 0.25rem 0.5rem"
                  key={index}
                >
                  <CardProduct key={index} {...child} />
                </Box>
              ))
            )}
          </HStack>
        </div>
      </Flex>
    </Layout>
  );
};

export default DashboardPage;
