import { extendTheme } from "@chakra-ui/react";
// These are the default breakpoints
const breakpoints = {
    sm: '23em',
    md: '48em',
    lg: '62em',
    xl: '80em',
    '2xl': '96em',
  }

// include custom option in here, like colors, fonts...
const customTheme={
    breakpoints,
    fonts: {
        body: `'Open Sans', sans-serif`,
      },
    colors:{
        red:{
          100:'#D91F05',  
          50:'#FFD8D2',  
        },
        black:{
            900:'#000000',
            500:'#333E48',
            400: '#313B45',
        },
        brown:{
            100: '#736161',
            200: '#626166',
        },
        blue:{
          500: '#1A74CD',
          400: '#C5E1F7',
        },
        yellow:{
          500: '#FDC742',
          400: '#FFF2D1',
        },
        green:{
          500: '#0BB873',
          400: '#C2FFE7',
        },
        grey:{
          100: '#F4F7F9',
          400: '#B0C1E1',
        }

    }
};
const theme = extendTheme(customTheme);
export default theme;