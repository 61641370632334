//@ts-ignore
import { createRoot } from "react-dom/client";
import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import theme from "./styles/theme";
const rootElement = document.getElementById("root");
const root = createRoot(rootElement!);

root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
