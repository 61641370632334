import {
  Box,
  Flex,
  HStack,
  IconButton,
  Img,
  Spacer,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import SimpleCard from "../SimpleCard";
import { Text } from "@chakra-ui/react";
import SimpleTag from "../SimpleTag";
import ShowValue from "./ShowValue";
import ProgressBarWithPercent from "../ProgressBarWithPercent";
import iconButtonModal from "../../assets/images/icons/card/icon_information_details.svg";
import { IProductGroup } from "../../hooks/DashboardContext";
import ModalCardProduct from "./ModalCardProduct";
import iconGreenDrop from "../../assets/images/icons/card/icon_green_drop.svg";
import iconYellowDrop from "../../assets/images/icons/card/icon_yellow_drop.svg";
import iconRedDrop from "../../assets/images/icons/card/icon_red_drop.svg";
import { QuestionOutlineIcon } from "@chakra-ui/icons";

const multiplyIcons = (icon: string, number: number) => {
  return Array.from({ length: number }).map((item, index) => (
    <Flex key={index}>
      <Img src={icon} pr="0.138rem" />
    </Flex>
  ));
};

const defaultMessageWhenWithoutStatus = (
  <Flex title="Não há ícones a serem exibidos" style={{ cursor: "pointer" }}>
    <QuestionOutlineIcon w={3} h={3} color="grey.400" />
  </Flex>
);

let icon: JSX.Element;
const status = (daysStock: string) => {
  switch (daysStock) {
    case "low":
      icon = <Flex>{multiplyIcons(iconRedDrop, 1)}</Flex>;
      break;
    case "mid":
      icon = <Flex>{multiplyIcons(iconYellowDrop, 2)}</Flex>;
      break;
    case "high":
      icon = <Flex>{multiplyIcons(iconGreenDrop, 3)}</Flex>;
      break;
    case null:
      icon = defaultMessageWhenWithoutStatus;
      break;
  }
  return icon;
};

const CardProduct = ({ ...props }: IProductGroup) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleSizeClick = () => {
    setIsOpen(true);
  };

  return (
    <>
      <SimpleCard>
        <Flex
          w="full"
          borderBottom="0.063rem solid rgba(176,193,225,0.3) "
          p="1.25rem 1.25rem 0.625rem 1.25rem"
        >
          <VStack
            spacing={0}
            display="flex"
            justifyContent="left"
            alignItems="left"
          >
            <HStack spacing={0}>
              <Text fontSize="1.5rem" color="black.400" fontWeight="bold">
                {props.productGroup}
              </Text>
              <Text
                fontSize="0.813rem"
                color="grey.400"
                display="grid"
                pt={3}
                pl={1}
              >
                em m
              </Text>
              <Text
                as="sub"
                fontSize="0.813rem"
                color="grey.400"
                display="grid"
              >
                3
              </Text>
            </HStack>
            <HStack>
              <Text
                fontSize="1rem"
                color="black.400"
                display="grid"
                fontWeight="semibold"
              >
                Dias de estoque: {props.daysOfStoch}
              </Text>
              {props.alert
                ? status(props.alert)
                : defaultMessageWhenWithoutStatus}
            </HStack>
          </VStack>

          <Spacer />
          <Box>
            <IconButton
              onClick={() => handleSizeClick()}
              variant="unstyled"
              aria-label="iconButtonModal"
              icon={<Img src={iconButtonModal} />}
              size="0.625rem"
              h="0.938rem"
              _focus={{ outline: "none" }}
              _active={{ active: "none" }}
              pt={2}
              pr={3}
            />
          </Box>
        </Flex>

        <Flex
          w="full"
          p="1.25rem 1.25rem 0.625rem 1.25rem"
          _hover={{
            cursor: "grab",
          }}
        >
          <Box w="100%" color="white">
            <SimpleTag color="red.100" bg="red.50" label=" Volume em uso" />

            <HStack mt="0.75rem">
              <ShowValue
                value={props.totalVolumeAvailable}
                description="Puro"
              />
              <ShowValue
                value={props.totalVolumeAvailableMixed}
                description="Misturado"
              />
            </HStack>
          </Box>
          <Spacer />
          <Box w="100%" color="white">
            <SimpleTag
              color="yellow.500"
              bg="yellow.400"
              label=" Volume de venda do dia"
            />
            <HStack mt="0.75rem">
              <ShowValue
                width="100%"
                value={props.totalSales}
                description="Total de misturado e puro"
              />
            </HStack>
          </Box>
        </Flex>

        <Flex
          w="full"
          p="0.625rem 1.25rem 0.625rem 1.25rem"
          _hover={{
            cursor: "grab",
          }}
        >
          <Box w="100%" color="white">
            <SimpleTag
              color="green.500"
              bg="green.400"
              label="Capacidade total do tanque"
            />
            <HStack mt="0.75rem">
              <ShowValue value={props.totalCapacity} />
            </HStack>
          </Box>
          <Spacer />
          <Box w="100%" color="white">
            <SimpleTag
              color="blue.500"
              bg="blue.400"
              label="Volume cota do dia"
            />
            <HStack mt="0.75rem">
              <ShowValue
                width="100%"
                value={props.totalQuote}
                description="Total de misturado e puro"
              />
            </HStack>
          </Box>
        </Flex>
        <Flex
          w="full"
          borderBottom="0.063rem solid rgba(176,193,225,0.3) "
          p="0.625rem 1.25rem 2.5rem 1.25rem"
          _hover={{
            cursor: "grab",
          }}
        >
          <Box w="100%" color="white">
            <SimpleTag color="red.100" bg="grey.100" label="Taxa de ocupação" />
            <HStack>
              <ProgressBarWithPercent
                value={props.totalCapacityUsage}
                color="red.100"
              />
            </HStack>
          </Box>

          <Spacer />
          <Box w="100%" color="white">
            <SimpleTag color="red.100" bg="grey.100" label="Cota dia em uso" />
            <HStack>
              <ProgressBarWithPercent
                value={props.totalQuoteUsage}
                color="red.100"
              />
            </HStack>
          </Box>
        </Flex>

        <Flex
          w="full"
          p="0.625rem 1.25rem 0.625rem 1.25rem"
          _hover={{
            cursor: "grab",
          }}
        >
          <Box w="100%" color="white">
            <SimpleTag
              color="blue.500"
              bg="grey.100"
              label="Volume temporário de balsa"
            />
            <HStack>
              <ShowValue value={props.totalVolumeTempAvailableVTB} />
            </HStack>
          </Box>
          <Spacer />
          <Box w="100%" color="white">
            <SimpleTag
              color="blue.500"
              bg="grey.100"
              label="Volume temporário fora da balsa"
            />
            <HStack>
              <ShowValue value={props.totalVolumeTempAvailableNVTB} />
            </HStack>
          </Box>
        </Flex>
      </SimpleCard>
      <ModalCardProduct
        onOpenModelCustom={handleSizeClick}
        onCloseCustom={() => {
          setIsOpen(false);
        }}
        isOpenModal={isOpen}
        {...props}
      />
    </>
  );
};

export default CardProduct;
