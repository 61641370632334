import React from "react";
import {
  Box,
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

type DataModal = {
  titleModal?: string;
  titleModalCustom?: JSX.Element;
  titleButton?: string;
  buttonCloseEnable: boolean;
  customButton?: any;
  children: any;
  onCloseCustom: () => void;
  isOpenModal: boolean;
  onOpenModelCustom: () => void;
  size?: string;
  isCentered?: boolean;
};

const SimpleModal = ({
  titleModal,
  titleModalCustom,
  titleButton,
  buttonCloseEnable = true,
  children,
  customButton,
  onCloseCustom,
  isOpenModal,
  onOpenModelCustom,
  size = "2xl",
  isCentered = false,
}: DataModal) => {
  const [scrollBehavior] = React.useState("inside");
  const btnRef = React.useRef(null);

  return (
    <>
      <Button
        mt={3}
        ref={btnRef}
        onClick={onOpenModelCustom}
        variant="link"
        color="#D91F05"
        fontSize="sm"
        _focus={{ outline: "none" }}
        _active={{ active: "none" }}
        _hover={{ textUnderlineOffset: "none" }}
      >
        {titleButton}
      </Button>

      <Modal
        isCentered={isCentered}
        size={size}
        onClose={onCloseCustom}
        finalFocusRef={btnRef}
        isOpen={isOpenModal}
        //@ts-ignore
        scrollBehavior={scrollBehavior}
      >
        <ModalOverlay />
        <ModalContent borderRadius={10}>
          <ModalHeader color="#313B45">
            {titleModal}
            {titleModalCustom}
          </ModalHeader>
          <ModalCloseButton
            _focus={{ outline: "none" }}
            variant="unstyled"
            style={{
              margin: "0.4rem 0.25rem 0.25rem 0.25rem",
              background: "transparent",
              fontSize: "0.8rem",
              color: "#313B45",
              fontWeight: 900,
            }}
          />
          <Divider orientation="horizontal" color="red" />

          <ModalBody
            css={{
              "&::-webkit-scrollbar": {
                width: "0.4rem",
              },
              "&::-webkit-scrollbar-track": {
                width: "0.4rem",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#736161",
                borderRadius: "0.5rem",
              },
            }}
          >
            {children}
          </ModalBody>
          <ModalFooter justifyContent={"center"}>
            {buttonCloseEnable ? (
              <Button onClick={onCloseCustom}>Close</Button>
            ) : null}
            <Box>{customButton}</Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SimpleModal;
