import React from "react";
import { Box } from "@chakra-ui/react";

const SimpleCard: React.FC = ({ children }) => {
  return (
    <Box
      bg="white"
      color="black"
      w="29.5rem"
      h="auto"
      borderRadius={16}
      boxShadow="0rem 0.25rem 0.25rem 0rem rgba(176, 193, 225, 0.25)"
    >
      {children}
    </Box>
  );
};

export default SimpleCard;
