import { Text, VStack } from "@chakra-ui/react";
import React from "react";

interface IShowValue {
  value?: number;
  valueWithString?: string;
  title?: string;
  description?: string;
  width?: string;
}

const ShowValueInModal = ({
  value,
  valueWithString,
  title,
  description,
  width = "50%",
}: IShowValue) => {
  return (
    <VStack
      display="flex"
      alignItems="flex-start"
      justifyContent="flex-start"
      textAlign="left"
      w={width}
      spacing={0}
    >
      <Text fontWeight={500} color="grey.400" fontSize="1rem">
        {title}
      </Text>
      <Text fontWeight={600} color="brown.200" fontSize="1rem">
        {value}
      </Text>
      <Text fontWeight={500} color="grey.400" fontSize="0.85rem">
        {description}
      </Text>
      <Text fontWeight={600} color="brown.200" fontSize="1rem">
        {valueWithString}
      </Text>
    </VStack>
  );
};

export default ShowValueInModal;
