import { Tag } from "@chakra-ui/react";
import React from "react";

interface ITag {
  bg: string;
  color: string;
  label: string;
}

const SimpleTag = ({ bg, color, label }: ITag) => {
  return (
    <>
      <Tag
        variant="solid"
        color={color}
        bg={bg}
        p="0.4rem 0.6rem 0.4rem 0.6rem"
        fontWeight="bold"
        fontSize="0.75rem"
        borderRadius={8}
      >
        {label}
      </Tag>
    </>
  );
};

export default SimpleTag;
